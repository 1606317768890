/*********************************************************
	* CORE module
	* Manage modules & utilities & vars
	* v2
/*********************************************************/
(function() {

	/*********************************************************
		* Constructor
	/*********************************************************/
	this.CORE = {

		init: function() {
			this.utils = {};
			this.controllers = {};

			this.initUtils();
			this.initControllers();

		},
		initControllers: function() {
			this.controllers.ui = new UIController({
				'exec': true
			});
		},
		initUtils: function() {
			log('%cUTILS :: Ready', 'color: #1BBC9B;');
		}

	};

}());

$(document).ready(function() {
	window.CORE.init();
	log('%cCORE :: Ready', 'color: #1BBC9B;');
});