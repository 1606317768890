/* self-executing anonymous function */
(function($) {

	$.fn.numbersOnly = function() {

		this.on("input", function(e) {
			this.value = this.value.replace(/[^0-9]/g, '');
		});

	};

}(jQuery));