var UIController = function(params) {

	if (params.exec) {
		init();
	} else {
		if ($('body').hasClass(params.classname)) init();
	}

	function init() {

		log('%cUI-Controller :: Done', 'color: #1BBC9B;');


		// Check if all images are loaded before showing the website
		imagesLoaded($('body'), function() {
			setTimeout(function() {
				//pageTransition();
				buildHomepage();
			}, 500);
		});



	}



	function buildHomepage() {



		anime({
			targets: '.home__logo .animation',
			duration: 1000,
			delay: 0,
			easing: 'easeOutExpo',
			scale: [.5, 1],
			opacity: [0, 1]
		});

		anime({
			targets: '#home_title',
			duration: 1000,
			delay: 0,
			easing: 'easeOutBack',
			translateY: [-20, 0],
			begin: function() {
				$('.page--home').show();
			}
		});


		anime({
			targets: '#home_subtitle',
			duration: 1000,
			delay: 0,
			easing: 'easeOutBack',
			translateY: [20, 0]
		});


		anime({
			targets: '#home_contact',
			duration: 1000,
			delay: 0,
			easing: 'easeOutBack',
			translateY: [-20, 0],
			begin: function() {
				$('.page--home').show();
			}
		});


	}



	return {
		'init': init
	};
};